<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>상황별 발생 건수</b>
		</div>
		<!-- Search -->
		<div ref="panelFlat" class="panel panel-flat">
			<div class="panel-body">
				<form action="#" @submit.prevent="search" @keyup.enter.prevent="search">
					<div class="row">
						<div>
							<div class="form-group">
								<!-- 현장 -->
								<label>{{ detailsFieldMap.siteId }}</label>
								<select2 v-model="searchVM.siteId" :disabled="isSiteDisabled">
									<option value="">전체</option>
									<option v-for="item in searchOptions.siteOptions" :key="item.siteId" :value="item.siteId">
										{{ item.siteNm }}
									</option>
								</select2>
							</div>
						</div>
						<div>
							<div class="form-group">
								<!-- 발생구분 -->
								<label>{{ detailsFieldMap.eventType }}</label>
								<select2 v-model="searchVM.eventType">
									<option value="">전체</option>
									<option v-for="item in searchOptions.eventTypeOptions" :key="item.value" :value="item.value">
										{{ item.text }}
									</option>
								</select2>
							</div>
						</div>
						<div>
							<div class="form-group">
								<!-- CCTV -->
								<label>{{ detailsFieldMap.cctvInstallPlaceCont }}</label>
								<select2 v-model="searchVM.cctvInstallPlaceCont">
									<option value="">전체</option>
									<option v-for="item in searchOptions.cctvOptions" :key="item.cctvInstallPlaceCont" :value="item.cctvInstallPlaceCont">
										{{ item.cctvInstallPlaceCont }}
									</option>
								</select2>
							</div>
						</div>
						<div>
							<div class="form-group">
								<label style="margin-top: 8px; padding-right: 7px">이력</label>
								<div class="checkbox" style="margin-bottom: 14px">
									<input type="checkbox" id="check" v-model="searchVM.isCheckedCctvAll" />
								</div>
							</div>
						</div>
					</div>
					<div class="row">
						<div period>
							<!-- 요청일자 -->
							<div class="form-group" period>
								<label style="margin-right: 10px">{{ detailsFieldMap.period }}</label>
								<input type="date" class="form-control" style="margin-right: 10px" v-model="searchVM.periodStrtDt" :format="'yyyy-MM-dd'" />
								~
								<input
									type="date"
									class="form-control"
									style="margin-left: 10px"
									v-model="searchVM.periodEndDt"
									:format="'yyyy-MM-dd'"
									:min="searchVM.periodStrtDt"
									:max="maxDay"
								/>
							</div>
						</div>
						<div class="mb-10 text-right" style="float: right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div id="detailsChart" class="row" v-show="rowSelected">
			<div class="col-lg-12">
				<!-- 1. 일별 API 호출(최근N일) -->
				<div class="panel panel-flat">
					<div class="panel-heading">
						<h6 class="panel-title">
							<i class="icon-stats-growth position-left"></i>
							<b>막대 차트</b>
						</h6>
					</div>
					<div class="panel-body">
						<div id="columnChart"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Select2 from '@/components/Select2';
import moment from 'moment';
import apiIndex from '../../api/index';
const dangerOccurUrl = apiIndex.dangerOccur;
const objectDangerOccurStatistic = apiIndex.objectDangerOccurStatistic;

let axiosExtention;

export default {
	components: {
		select2: Select2,
	},
	data: () => ({
		pageParam: null,
		searchVM: {},
		// 상세 select options
		detailsOptions: {},
		// 검색 select options
		searchOptions: {},
		// 적용된 검색조건
		searchState: {},
		detailsFieldMap: {
			siteId: '현장',
			eventType: '발생구분',
			cctvInstallPlaceCont: 'CCTV',
			period: '요청일자',
		},
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {},
		chartJson: [],
		isSiteDisabled: false,
		// openDetailsForm: false,
		rowSelected: false,
		objectAndDangerCntMap: {},
		objectNm: [],
		objectTargetDangerCnt: [],
		totalObjectTargetDangerCnt: 0,
		// objectPercent: [],
		//periodEndDt의 지정 가능한 최대 일자
		maxDay: '',
	}),
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('objectDangerOccurStatisticBarPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		// 본사관리자가 아니면, 현장 select box Disabled
		if (this.pageParam.loginUserRolecd !== '00') {
			this.isSiteDisabled = true;
		}
		this.searchVM = {
			siteId: this.pageParam.loginUserSiteId,
			eventType: '',
			isCheckedCctvAll: false,
			cctvInstallPlaceCont: '',
			periodStrtDt: moment().add(-6, 'day').format('YYYY-MM-DD'),
			periodEndDt: moment().format('YYYY-MM-DD'),
		};
		this.searchOptions = {
			siteOptions: this.pageParam.siteOptions,
			eventTypeOptions: [
				{
					value: 'danger',
					text: '위험',
				},
				{
					value: 'warnWork',
					text: '주의',
				},
			],
			cctvOptions: this.pageParam.cctvOptions,
		};

		axiosExtention = this.$jarvisExtention.axiosExtention;
	},
	mounted() {
		this.search();
	},
	computed: {
		//차트
		charData() {
			return {
				title: {
					text: '대상물별위험통계',
				},
				legend: {
					visible: false,
				},
				seriesDefaults: {
					type: 'bar',
					labels: {
						visible: true,
						background: 'transparent',
						template: '#= kendo.format("{0}%", dataItem.percentage)#',
					},
				},
				series: [
					{
						name: '발생건수',
						data: this.objectTargetDangerCnt,
						axis: 'count',
					},
				],
				valueAxis: [
					{
						title: {
							text: '발생건수',
						},
						name: 'count',
						min: 0,
						line: {
							visible: true,
						},
						minorGridLines: {
							visible: true,
						},
					},
				],
				categoryAxis: {
					categories: [],
					majorGridLines: {
						visible: false,
					},
				},
				tooltip: {
					visible: true,
					template: '#= series.name #: #= value #',
				},
			};
		},
	},
	watch: {
		chartJson() {
			//details활성화 시 스크롤 이동처리
			this.$nextTick(function () {
				window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
			});
		},
		'searchVM.siteId': {
			handler(value, oldValue) {
				if (oldValue !== null) {
					this.$axios
						.post(dangerOccurUrl.inqDangerOccurCctvInfo, this.searchVM)
						.then(
							function (r) {
								this.searchOptions.cctvOptions = r.data;
								this.searchVM.cctvInstallPlaceCont = '';
							}.bind(this),
						)
						.catch(axiosExtention.buildErrorHandler());
				}
			},
		},
		'searchVM.isCheckedCctvAll': {
			handler() {
				this.$axios
					.post(dangerOccurUrl.inqDangerOccurCctvInfo, this.searchVM)
					.then(
						function (r) {
							this.searchOptions.cctvOptions = r.data;
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			},
		},
		//조회기간을 최대 x개월 간으로 하기 위해
		'searchVM.periodStrtDt': {
			handler(value) {
				this.maxDay = moment(value).add(process.env.VUE_APP_MAX_MONTH, 'month').format('YYYY-MM-DD');
			},
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('columnChart');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		//유효한 요청일자 기간인지 확인
		isValidPeriod() {
			if (
				this.searchVM.periodStrtDt == null ||
				this.searchVM.periodStrtDt == '' ||
				this.searchVM.periodEndDt == null ||
				this.searchVM.periodEndDt == ''
			) {
				alert('날짜를 입력해주세요');
				return false;
			}
			if (new Date(this.searchVM.periodStrtDt) > new Date(this.searchVM.periodEndDt)) {
				alert('유효한 날짜를 입력해주세요');
				return false;
			}
			return true;
		},
		resetChartData() {
			this.chartJson = [];
			this.objectAndDangerCntMap = {};
			this.objectNm = [];
			this.objectTargetDangerCnt = [];
			this.totalObjectTargetDangerCnt = 0;
			// this.objectPercent = [];
		},
		search() {
			if (this.isValidPeriod() == false) {
				return;
			}
			this.resetChartData();
			this.setChartData();
		},
		calPercentage(value) {
			if (value == 0) {
				return 0;
			}
			return Math.round(((value * 100) / this.totalObjectTargetDangerCnt) * 100) / 100;
		},
		setObjectNmAndobjectTargetDangerCnt() {
			for (let key in this.objectAndDangerCntMap) {
				this.objectNm.push(key);
				let percent = this.calPercentage(this.objectAndDangerCntMap[key]);
				this.objectTargetDangerCnt.push({ value: this.objectAndDangerCntMap[key], percentage: percent });
				// this.objectPercent.push({ value: percent });
			}
		},
		//charData세팅하기
		setChartData() {
			this.$axios
				.post(objectDangerOccurStatistic.inqObjectDangerOccurStatistic, this.searchVM)
				.then(
					function (response) {
						this.chartJson = response.data.objectDangerOccur;
						this.totalObjectTargetDangerCnt = response.data.objectDangerOccurTotal;

						this.chartJson.forEach(element => {
							this.$set(this.objectAndDangerCntMap, element.objectNm + '\n' + `${element.targetNm || ''}`, element.objectTargetDangerCnt);
						});
						this.setObjectNmAndobjectTargetDangerCnt();
						this.rowSelected = true;
						this.loadChartData();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler());
		},
		loadChartData() {
			this.charData.dataSource = { data: this.chartJson };
			this.charData.categoryAxis = {
				categories: this.objectNm,
			};

			this.drawChart();
		},
		drawChart() {
			console.log(this.charData);
			$('#columnChart').kendoChart({
				chartArea: { width: this.$refs.panelFlat.clientWidth - 50, height: this.objectNm.length * 50 + 150 },
				dataSource: this.charData.dataSource,
				categoryAxis: this.charData.categoryAxis,
				valueAxis: this.charData.valueAxis,
				seriesDefaults: this.charData.seriesDefaults,
				series: this.charData.series,
				legend: {
					position: 'bottom',
				},
				tooltip: this.charData.tooltip,
			});

			var chart = $('#columnChart').data('kendoChart');
			chart.showTooltip(this.objectNm[0]);
		},
	},
};
</script>

<style>
span.select2-selection.select2-selection--single[aria-disabled='true'] {
	background: #eee;
}
.checkbox {
	display: inline-block !important;
	margin-left: 0.7em;
}

#rootVM > .panel .panel-body {
	padding: 40px !important;
	margin: 0 !important;
}

#rootVM > .panel .panel-body form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#rootVM > .panel .row {
	display: flex;
	width: 900px;
}

#rootVM > .panel .row > div {
	width: 100%;
}

#rootVM > .panel .row .form-group > span {
	width: 60% !important;
	margin-left: 10px;
}

#rootVM > .panel .row .form-group input {
	display: inline-block;
}

#rootVM > .panel .row > [period] {
	width: 100%;
}

#rootVM > .panel .row:first-child > .checkbox-wrapper {
	width: 200px;
}

#rootVM > .panel .row:first-child > .checkbox-wrapper:last-child {
	width: 500px;
}

#rootVM > .panel .row:last-child .mb-10 {
	width: 200px;
	margin-left: auto;
}

.row .form-group > span {
	margin-left: 10px;
}
.form-group input {
	display: inline-block;
}

#detailsForm > .panel > .panel-heading > div {
	display: flex;
	margin-bottom: 15px;
}
#detailsForm > .panel > .panel-heading > div > div {
	display: flex;
	align-items: center;
}
#detailsForm > .panel > .panel-heading > div > div > * {
	margin-right: 5px;
}
#detailsForm > .panel > .panel-heading span {
	margin-right: 10px;
}

#detailsForm > .panel > .panel-body > div {
	width: calc(50% - 10px);
	display: inline-flex;
	flex-direction: column;
}
#detailsForm > .panel > .panel-body > div:last-child {
	float: right;
}

#detailsForm .panel-heading {
	font-weight: bold;
}
#detailsForm .panel-heading input {
	max-width: 160px;
}

#detailsForm .law-panel .panel-body textarea {
	height: 200px;
	resize: none;
	margin-bottom: 15px;
}
#detailsForm .law-panel .btn-wrapper {
	padding: 0;
	margin-bottom: 15px;
}
#detailsForm .law-panel .btn-wrapper .btn {
	width: calc(50% - 5px);
	margin: 0;
}
#detailsForm .law-panel .btn-wrapper .btn:last-child {
	float: right;
}
#detailsForm tr.active td {
	background: #ddd;
}

/* #detailsForm .panel-body .panel-flat { max-height:100px; } */
#detailsForm .panel-body .panel-flat .panel-body {
	display: flex;
	flex-direction: column;
}

#detailsForm .table-wrapper {
	max-height: 200px;
	overflow-y: auto;
}
.modal .table-wrapper {
	max-height: 300px;
	overflow-y: auto;
}
.modal .table tbody tr:hover {
	cursor: pointer;
}

.table th {
	background: #eee;
	vertical-align: middle !important;
	font-weight: bold;
}
.panel-body .table th:first-child {
	width: 120px;
	text-align: center;
}

.table td {
	vertical-align: middle;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 1px;
}
.panel-body .table td:first-child {
	text-align: center;
}
.panel-body .table td:last-child {
	text-align: center;
	width: 80px;
}

.table [empty] {
	display: none;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

.btn-wrapper {
	width: 100%;
	clear: both;
	padding-bottom: 30px;
	padding-right: 20px;
}
.btn-wrapper .btn {
	margin-left: 10px;
}

.modal .search {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}
[type='search'] {
	display: inline-block;
	width: 180px;
	margin-right: 5px;
}

[period] [type='date'] {
	width: 150px !important;
}

.col-lg-12 {
	padding-right: 0 !important;
}
</style>
